<template>

  <div class="form container">
    <div class="window">
      <div class="account__container">
        <a href="#" class="subscribes__back" @click.prevent="goBack">
          <span>
            <img src="@/assets/images/arrow-left_purple.svg" alt="back">
          </span>
          Back
        </a>
        <h1 class="subscribes__title">Subscriptions</h1>
<!--        <p class="subscribes__description" href="#">-->
<!--          But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the…-->
<!--        </p>-->
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div
              class="subscribes-item"
              :class="{'subscribes-item_active': true}"
            >
              <img class="subscribes-item__icon" src="@/assets/images/circle-free.svg" alt="free plan icon">
              <h3 class="subscribes-item__title">Free plan</h3>
              <p class="subscribes-item__description">uploading files up to 10mb</p>
              <p class="subscribes-item__cost">free</p>
              <button class="subscribes-item__button">Selected</button>
            </div>
          </div>

          <div class="col-lg-4 col-md-6">
            <div
              class="subscribes-item"
              :class="{'subscribes-item_active': profileCurrentPlan === 'month-plan'}"
            >
              <img class="subscribes-item__icon" src="@/assets/images/circle-unlimited.svg" alt="free plan icon">
              <h3 class="subscribes-item__title">Monthly</h3>
              <p class="subscribes-item__description">uploading files of any size</p>
              <p class="subscribes-item__cost">$ 0.99<span class="subscribes-item__cost_small">/mo</span></p>
              <button
                @click="paySubscription('month-plan')"
                class="subscribes-item__button"
                :disabled="isPaymentLoading || profileCurrentPlan === 'month-plan'">
                <span v-if="profileCurrentPlan !== 'month-plan'">Select</span>
                <span v-else>Selected</span>
<!--                <b-spinner v-if="isPaymentLoading" small variant="dark"></b-spinner>-->
              </button>
            </div>
          </div>

          <div class="col-lg-4 col-md-6">
            <div
              class="subscribes-item"
              :class="{'subscribes-item_active': profileCurrentPlan === 'year-plan'}"
            >
              <img class="subscribes-item__icon" src="@/assets/images/circle-unlimited.svg" alt="free plan icon">
              <h3 class="subscribes-item__title">Yearly</h3>
              <p class="subscribes-item__description">uploading files of any size</p>
              <p class="subscribes-item__cost">$ 9.99<span class="subscribes-item__cost_small">/year</span></p>
              <button
                @click="paySubscription('year-plan')"
                class="subscribes-item__button"
                :disabled="isPaymentLoading || profileCurrentPlan === 'year-plan'">
                <span v-if="profileCurrentPlan !== 'year-plan'">Select</span>
                <span v-else>Selected</span>
<!--                <b-spinner v-if="isPaymentLoading" small variant="dark"></b-spinner>-->
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>


</template>

<script>
  import {MODULE_NAME as SUBSCRIPTIONS, PAY_SUBSCRIPTION} from "@/store/modules/subscriptions";
  import {loadStripe} from '@stripe/stripe-js';
  import {MODULE_NAME} from "@/store/modules/profile";

  export default {
    name: 'ChooseSubscription',
    computed: {
      profile() {
        return this.$store.state[MODULE_NAME].profile.data
      },
      profileCurrentPlan() {
        return this.profile ? this.profile.subscription && this.profile.subscription.type : ''
      },
      stripePubKey() {
        return this.$store.state[SUBSCRIPTIONS].variables.pubKey
      },
      stripeInstance() {
        return loadStripe(this.stripePubKey)
      },
      paymentState() {
        return this.$store.state[SUBSCRIPTIONS].paySubscription
      },
      isPaymentLoading() {
        return this.paymentState.loading
      },
      payStartResult() {
        return this.paymentState.data
      },
      payStartError() {
        return this.paymentState.error
      },
    },
    methods: {
      goBack() {
        // console.log('touter', this.$router)
        this.$router.go(-1)
        // this.$router.replace('/')
      },
      async paySubscription(plan) {
        const data = await this.$store.dispatch(PAY_SUBSCRIPTION, {plan})

        const instance = await this.stripeInstance
        console.log(data)
        const checkout = await instance.redirectToCheckout({ sessionId: data.sessionId })
        console.log(checkout)
      }
    }
  }
</script>
